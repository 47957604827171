import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';

import copernicus from '../../assets/img/copernicus.png';
import gisat from '../../assets/img/gisat.png';

import './style.scss';

const IntroOverlay = ({
	chartExpanded,
	dataModeChosen,
	chooseDataMode,
	changeState,
	hidden,
	open = true,
}) => {
	const handleOverlay = dataMode => {
		if (dataModeChosen) {
			changeState(!open);
		} else {
			chooseDataMode(dataMode);
		}
	};

	const classes = classnames('insar-IntroOverlay', {
		'is-minimised': !open,
		'is-chartExpanded': chartExpanded,
		'is-hidden': hidden,
	});

	return (
		<div className={classes}>
			<Button
				className="insar-IntroOverlay-control"
				onClick={handleOverlay.bind(this, null)}
				icon="back"
				circular
				invisible
			/>
			<div
				className="insar-IntroOverlay-header"
				onClick={handleOverlay.bind(this, null)}
			>
				<span>
					Copernicus Emergency Management Service
					Risk&nbsp;&&nbsp;Recovery&nbsp;Mapping
				</span>
				<h1>EMSN091: Manila</h1>
			</div>
			<div className="insar-IntroOverlay-content" tabIndex={-1}>
				<div tabIndex={-1}>
					<h3>
						Assessing changes in subsidence rates in the low Pampanga river
						basin and Manila area, Philippines
					</h3>
					<p>
						The Copernicus EMS service [EMSN091] Manila: Ground deformations was
						activated by a request from the Federal Office of Civil Protection
						and Disaster Assistance (BBK) on behalf of Deutsche Gesellschaft für
						Internationale Zusammenarbeit (GIZ) – authorized user, and the
						University of Philippines – local user. Several sources suggest that
						the Manila NCR and lower Pampanga river basin, Philippines, has been
						affected by ground subsidence phenomena impacting settlements and
						increasing flood risk.
					</p>
					<p>
						The service outputs provide evidence of ground motion patterns in
						the areas of interest using multi-temporal satellite SAR data
						analysis – by Persistent Scatterers Interferometry method (PSI).
						Gisat processed and analyzed up to 6-years-long time series of radar
						measurements from the Sentinel-1 satellite mission (2015-Apr 2021).
						Two stacks of images from Ascending and Descending orbital
						trajectories of Sentinel-1 were utilized. The results provide
						insight into the localization and extent of sinking zones and the
						severity of phenomena related to estimated motion velocity or
						different adversary patterns.
					</p>
					<p>
						The Interferometric measurements panel provides tools to visualize
						patterns of the average ground motion velocities. Ground motion
						velocity measured in the satellite’s native line-of-sight geometry
						(LOS) was geometrically projected to the vertical motion field for
						both Ascending and Descending tracks. Furthermore, vertical and
						horizontal motion fields were obtained by decomposition of
						measurements from opposite tracks. In addition, value-adding
						features such as temporally coherent targets, annual motion trends
						and classification of motion dynamics such as motion acceleration
						can be visualized.
					</p>
				</div>
			</div>
			<div className="insar-IntroOverlay-cta">
				<Button
					primary
					large
					disabled={dataModeChosen}
					onClick={handleOverlay.bind(this, null)}
				>
					<>
						<span>Continue with data subset</span>
						<div>
							Select this option if you want to explore the app options and get
							a quick overview of the data.
						</div>
						<div>(~ 6 MB will be loaded gradually)</div>
					</>
				</Button>
				<Button
					large
					disabled={dataModeChosen}
					onClick={handleOverlay.bind(this, 'full')}
				>
					<>
						<span>Explore with full data</span>
						<div>
							Select this option if you want to explore the complete dataset.
							App interactivity may be limited.
						</div>
						<div>(~ 55 MB will be loaded gradually)</div>
					</>
				</Button>
			</div>
			<div className="insar-IntroOverlay-footer">
				<div className="insar-IntroOverlay-copyrights">
					© {`${new Date().getFullYear()}`}{' '}
					<a
						href="http://gisat.cz/content/en"
						target="_blank"
						rel="noreferrer noopener"
					>
						Gisat
					</a>
					, Copernicus Emergency Management Service (©{' '}
					{`${new Date().getFullYear()}`} European Union), EMSN091
				</div>
				<div className="insar-IntroOverlay-logos">
					<a href="http://gisat.cz/" target="_blank" rel="noreferrer noopener">
						<img alt="gisat_logo" src={gisat} />
					</a>
					<a
						href="https://www.copernicus.eu/en"
						target="_blank"
						rel="noreferrer noopener"
					>
						<img alt="copernicus_logo" src={copernicus} />
					</a>
				</div>
			</div>
		</div>
	);
};

IntroOverlay.propTypes = {
	open: PropTypes.bool,
	chartExpanded: PropTypes.bool,
	hidden: PropTypes.bool,
	dataModeChosen: PropTypes.bool,
	changeState: PropTypes.func,
	chooseDataMode: PropTypes.func,
};

export default IntroOverlay;
