import queryString from 'query-string';
import fetch from 'isomorphic-fetch';

const sortingFunctionsByType = {
	abs: (attribute, feature) => Math.abs(feature.properties[attribute]),
	trendDynamics: (attribute, feature) => {
		const value = feature.properties[attribute];
		if (value === 'NO_CLASS' || value === null) {
			return 0;
		} else if (value === 'CONST_TREND') {
			return 1;
		} else {
			return 2;
		}
	},
};

function request(url, method, query, payload) {
	if (query) {
		url += '?' + queryString.stringify(query);
	}

	return fetch(url, {
		method: method,
		body: payload ? JSON.stringify(payload) : null,
	}).then(
		response => {
			if (response.ok) {
				return response.json().then(body => {
					if (body) {
						return body;
					} else {
						throw new Error('no data returned');
					}
				});
			} else {
				throw new Error('response error');
			}
		},
		error => {
			throw error;
		}
	);
}

/**
 * Apply mask to original string
 * @param mask {string} use # for preserved character
 * @param originalString {string}
 * @returns {string} masked string
 */
function applyMaskToString(mask, originalString) {
	return mask.replace(/#/g, function (maskChar, originalChar) {
		return originalString[originalChar] ? originalString[originalChar] : '';
	});
}

export default {
	applyMaskToString,
	request,
	sortingFunctionsByType,
};
