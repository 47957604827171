/**
 * DEFAULT CONFIG VALUES FOR ALL APP INSTANCES
 *
 * Default values only.
 * Do not use this file for development, per-instance config, etc.
 */

export default {
	// interferometryDataRepository:
	// 	'https://ptr.gisat.cz/ftpstorage/applications/insar',
	interferometryDataRepository:
		'https://ptr.gisat.cz/ftpstorage/applications/emsn091Manila/interferometry',
};
