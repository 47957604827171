import ActionTypes from '../../../constants/ActionTypes';
import {commonReducers as common} from '@gisatcz/ptr-state';

const INITIAL_STATE = {};

const setProperty = (state, action) => {
	const {modelKey, property, value} = action;

	return {
		...state,
		byKey: {
			...state.byKey,
			[modelKey]: {
				...state.byKey[modelKey],
				data: {
					...state.byKey[modelKey].data,
					[property]: value,
				},
			},
		},
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.INSAR.VISUALIZATIONS.SET_ACTIVE_KEY:
			return common.setActive(state, action);
		case ActionTypes.INSAR.VISUALIZATIONS.SET_PROPERTY:
			return setProperty(state, action);
		case ActionTypes.INSAR.VISUALIZATIONS.UPDATE_STORE:
			return common.updateStore(state, action);
		default:
			return state;
	}
};
