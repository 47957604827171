import {forEach as _forEach} from 'lodash';
import {Action as CommonAction} from '@gisatcz/ptr-state';
import config from '../../../config';
import ActionTypes from '../../../constants/ActionTypes';
import utils from '../../../utils';
import Select from '../../Select';

// ============ creators ===========
function ensureDataForMap(type, trackKeys, fidColumnName, forceLoad) {
	return dispatch => {
		return dispatch(
			ensureDataByTypeAndTrackKey(type, trackKeys, fidColumnName, forceLoad)
		);
	};
}

function ensureDataByTypeAndTrackKey(
	type,
	trackKeys,
	fidColumnName,
	forceLoad
) {
	return (dispatch, getState) => {
		const interferometryConfig = Select.app.getLocalConfiguration(
			getState(),
			'data.interferometry'
		);
		const missingDataForTracks = [];
		const promises = [];

		const decimatedSuffix =
			interferometryConfig.dataMode === 'decimated'
				? interferometryConfig.decimatedSuffix
				: '';

		_forEach(trackKeys, trackKey => {
			const features =
				Select.insar.interferometryData.getFeaturesByTypeAndTrackKey(
					getState(),
					type,
					trackKey
				);
			if (!features || forceLoad) {
				const url = `${
					config.interferometryDataRepository
				}/${type}/${trackKey}${decimatedSuffix || ''}.json`;
				promises.push(utils.request(url, 'GET', null, null));
				missingDataForTracks.push(trackKey);
			}
		});

		if (promises.length) {
			dispatch(CommonAction.components.set('loadingOverlay', 'open', true));

			// the output is strictly ordered as the input as long as the input is strictly ordered
			return Promise.all(promises)
				.then(data => {
					if (data) {
						_forEach(data, (trackData, index) => {
							const trackKey = missingDataForTracks[index];
							dispatch(actionAddByTypeByTrackKey(type, trackKey, trackData));
							dispatch(
								actionAddByTypeByFeatureKey(
									type,
									trackKey,
									fidColumnName,
									trackData
								)
							);
						});
					}
				})
				.catch(err => new Error(err));
		} else {
			return Promise.resolve();
		}
	};
}

function ensureDataForPointStatistics(
	type,
	method,
	trackKey,
	fidColumnName,
	fid
) {
	return dispatch => {
		// for now, request allways for data
		let url = `${config.interferometryDataRepository}/${type}/${trackKey}/${fid}.json`;

		return utils.request(url, 'GET', null, null).then(data => {
			if (data) {
				dispatch(
					actionAddByTypeByFeatureKey(type, trackKey, fidColumnName, data, fid)
				);
			}
		});
	};
}

// ============ actions ===========

const actionAddByTypeByTrackKey = (trackType, trackKey, features) => {
	return {
		type: ActionTypes.INSAR.INTERFEROMETRY_DATA.ADD_BY_TYPE_BY_TRACK_KEY,
		trackType,
		trackKey,
		features,
	};
};

const actionAddByTypeByFeatureKey = (
	trackType,
	trackKey,
	fidColumnName,
	features,
	fid
) => {
	return {
		type: ActionTypes.INSAR.INTERFEROMETRY_DATA.ADD_BY_TYPE_BY_FEATURE_KEY,
		trackType,
		trackKey,
		fidColumnName,
		features,
		fid,
	};
};

// ============ export ===========

export default {
	ensureDataForMap,
	ensureDataForPointStatistics,
};
