import React from 'react';
import PropTypes from 'prop-types';
import {
	pickBy as _pickBy,
	forIn as _forIn,
	minBy as _minBy,
	maxBy as _maxBy,
} from 'lodash';
import moment from 'moment';

import {HoverHandler} from '@gisatcz/ptr-core';
import {ScatterChart} from '@gisatcz/ptr-charts';
import {utils} from '@gisatcz/ptr-utils';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import ChartWrapper from '../ChartWrapper';
import ReactResizeDetector from 'react-resize-detector';

import './style.scss';

class InterferometryChart extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			rangeType: 'dynamic',
			width: null,
		};

		this.setChartRangeType = this.setChartRangeType.bind(this);
		this.handleWidth = this.handleWidth.bind(this);
	}

	setChartRangeType(rangeType) {
		this.setState({
			rangeType,
		});
	}

	handleWidth(width) {
		this.setState({width});
	}

	render() {
		const remSize = utils.getRemSize();
		const attributes = this.props.attributes;
		const dAttributes = _pickBy(attributes, (value, key) => /^d_/.test(key)); // show d_attribute in chart for now
		let points = [];
		_forIn(dAttributes, (value, key) => {
			const dateString = key.split('_')[1];
			points.push({
				key,
				date: moment(dateString).toISOString(true),
				value,
			});
		});

		let defaultMax = 500;
		let defaultMin = -520;
		let max = _maxBy(points, point => point.value)?.value;
		let min = _minBy(points, point => point.value)?.value;

		if (this.state.rangeType === 'fixed') {
			max = defaultMax;
			min = defaultMin;
		} else if (this.state.rangeType === 'dynamic') {
			const diff = Math.abs(max - min);
			if (diff < 50) {
				const addition = Math.floor((100 - diff) / 4);
				min -= addition;
				max += addition;
			}
		}

		if (this.state.chartYAxisRangeType === 'fixed' && max < defaultMax)
			max = defaultMax;
		if (this.state.chartYAxisRangeType === 'fixed' && min > defaultMin)
			min = defaultMin;

		return (
			<ChartWrapper
				title={
					<>
						PS point displacement trend <span>(mm)</span>
					</>
				}
				settingItems={[
					{
						name: 'Y axis range',
						content: (
							<ButtonSwitch ghost small onClick={this.setChartRangeType}>
								<ButtonSwitchOption
									value="fixed"
									active={this.state.rangeType === 'fixed'}
								>
									Fixed (from -500 to 500)
								</ButtonSwitchOption>
								<ButtonSwitchOption
									value="dynamic"
									active={this.state.rangeType === 'dynamic'}
								>
									Dynamic (focused on breaks)
								</ButtonSwitchOption>
							</ButtonSwitch>
						),
					},
				]}
			>
				<HoverHandler>
					<div className="">
						<ReactResizeDetector handleWidth onResize={this.handleWidth} />
						<ScatterChart
							key="interferometry-chart"
							height={20}
							width={this.state.width / remSize}
							data={points}
							keySourcePath="key"
							nameSourcePath="key"
							xSourcePath="date"
							ySourcePath="value"
							pointRadius={5}
							border
							xScaleType="time"
							xOptions={{
								axisValueFormat: 'YYYY',
								popupValueFormat: 'D MMMM YYYY',
								name: 'Date',
								min: '2014-12-01T00:00:00.000Z',
								max: '2021-02-01T00:00:00.000Z',
							}}
							xValuesSize={2.5}
							yOptions={{
								tickCount: 10,
								min,
								max,
								unit: 'mm',
								name: 'Point displacement',
							}}
							yValuesSize={2.5}
							withoutYbaseline={false}
							diverging
							pointSymbol="plus"
						/>
					</div>
				</HoverHandler>
			</ChartWrapper>
		);
	}
}

InterferometryChart.propTypes = {
	attributes: PropTypes.object,
};

export default InterferometryChart;
