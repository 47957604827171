import ActionTypes from '../../../constants/ActionTypes';
import {commonReducers as common} from '@gisatcz/ptr-state';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.INSAR.ORBITAL_TRAJECTORIES.SET_ACTIVE_KEYS:
			return common.setActiveMultiple(state, action);
		case ActionTypes.INSAR.ORBITAL_TRAJECTORIES.UPDATE_STORE:
			return common.updateStore(state, action);
		default:
			return state;
	}
};
