import {connect} from '@gisatcz/ptr-state';

import Action from '../../state/Action';
import Select from '../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		expanded: Select.components.get(state, 'controlPanel', 'chartExpanded'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setChartExpanded: value => {
			dispatch(Action.components.set('controlPanel', 'chartExpanded', value));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
