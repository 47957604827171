import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Interferometry from './Interferometry';

import './style.scss';

class ControlPanel extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const classes = classnames('insar-ControlPanel', {
			'is-minimised': this.props.minimised,
			'is-chartExpanded': this.props.chartExpanded,
		});

		return (
			<div className={classes}>
				<div className="insar-ControlPanel-top-padding" />
				<div className="insar-ControlPanel-content">
					<div className="insar-ControlPanel-section">
						<div className="insar-ControlPanel-section-header">
							Ground deformation<span> - InSAR measurement points</span>
						</div>
						<Interferometry />
					</div>
				</div>
			</div>
		);
	}
}

ControlPanel.propTypes = {
	chartExpanded: PropTypes.bool,
	minimised: PropTypes.bool,
};

export default ControlPanel;
