// eslint-disable-next-line react/display-name
export default () => (
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="32px"
		height="32px"
		viewBox="0 0 32 32"
		xmlSpace="preserve"
		className="insar-icon direction"
	>
		<g>
			<path
				className="line"
				d="M 24.707031 10.292969 L 23.292969 11.707031 L 26.585938 15 L 22 15 L 22 17 L 26.585938 17 L 23.292969 20.292969 L 24.707031 21.707031 L 30.414062 16 L 24.707031 10.292969 z M 2 15 L 2 17 L 10 17 L 10 15 L 2 15 z M 12 15 L 12 17 L 20 17 L 20 15 L 12 15 z "
			/>
		</g>
	</svg>
);
