export default {
	activeViewKey: '5f02bf8f-e034-45f8-81b0-5266fd54af8a',
	interferometry: {
		fidColumnName: 'id_global',
		mapKey: 'interferometry',
		layerKey: 'interferometry',
		dataMode: 'decimated',
		dataModeChosen: false,
		decimatedSuffix: '_decimated',
		layersOrder: [
			'transitions',
			'recurrence',
			'seasonality',
			'change',
			'occurrence',
			'interferometry',
		],
		idMasks: {
			los: '####_###_####_11_######',
			vertg: '####_###_####_12_######',
			vert: '####_#####_11_######',
			ew: '####_#####_12_######',
		},
	},
};
