export default {
	byKey: {
		accel_lmy: {
			key: 'accel_lmy',
			data: {
				nameDisplay:
					'Acceleration value of displacement in the period of analysis',
				nameInternal: 'Acceleration value of displacement',
				unit: 'mm/yr',
				description: '(difference between vel_ly and vel_my)',
			},
		},
		acc_v_lmy: {
			key: 'acc_v_lmy',
			data: {
				nameDisplay:
					'Acceleration value of displacement rate in the period of analysis',
				nameInternal: 'Acceleration value of displacement',
				unit: 'mm/yr',
				description:
					'Acceleration value of displacement rate in the period of analysis (difference between vel_v_ly and vel_v_my)',
			},
		},
		acc_ew_lmy: {
			key: 'acc_ew_lmy',
			data: {
				nameDisplay:
					'Acceleration value of displacement rate  in the period of analysis',
				nameInternal: 'Acceleration value of displacement',
				unit: 'mm/yr',
				description:
					'Acceleration value of displacement rate  in the period of analysis (difference between vel_ew_ly and vel_ew_my)',
			},
		},
		aspect: {
			key: 'aspect',
			data: {
				nameDisplay: 'Topographic slope aspect (from DEM)',
				description: 'Slope aspect calculated from reference DEM',
			},
		},
		az_ang: {
			key: 'az_ang',
			data: {
				nameDisplay: 'Azimuth angle (LOS)',
				unit: 'deg',
				description: 'Global azimuth angle of the LOS',
			},
		},
		cl_accel_l: {
			key: 'cl_accel_l',
			data: {
				nameDisplay:
					'Acceleration value of displacement rate between the last and the previous reliable segment',
				nameInternal:
					'Acceleration value of displacement (last vs. previous segment)',
				unit: 'mm/yr',
			},
		},
		cl_dyn: {
			key: 'cl_dyn',
			data: {
				nameDisplay: 'Displacement trend dynamics',
				description:
					'Dynamics of displacement trend over the whole period of observation',
			},
		},
		cl_prg: {
			key: 'cl_prg',
			data: {
				nameDisplay: 'Displacement trend',
				description: 'Displacement trend over the whole period of observation',
			},
		},
		coh: {
			key: 'coh',
			data: {
				nameDisplay: 'Interferometric coherence',
				description:
					'Coherence - quality/precision measure ranging from 0 to 1 (0-worst, 1-best quality)',
			},
		},
		h_ref: {
			key: 'h_ref',
			data: {
				nameDisplay:
					'Estimated relative height of the point (above reference DEM)',
				unit: 'm',
				description:
					'Estimated relative (“InSAR”) height of the point w.r.t. InSAR reference DEM (GLO-30)',
			},
		},
		h_lid1m: {
			key: 'h_lid1m',
			data: {
				nameDisplay: 'Height obtained from LiDAR DTM',
				unit: 'm',
			},
		},
		h_pamz10m: {
			key: 'h_pamz10m',
			data: {
				nameDisplay: 'Height obtained from PAMZAM DTM',
				unit: 'm',
			},
		},
		h_cop30m: {
			key: 'h_cop30m',
			data: {
				nameDisplay: 'Height obtained from Copernicus GLO-30 DSM',
				unit: 'm',
			},
		},
		gid: {
			key: 'gid',
			data: {
				nameDisplay: 'ID',
				description:
					'Unique ID (LOS/Vertical-geometric: of persistent scatterer point)',
			},
		},
		id_global: {
			key: 'id_global',
			data: {
				nameDisplay: 'Persistent scatterer point global ID',
				description: 'Unique across all mappings',
			},
		},
		inc_ang: {
			key: 'inc_ang',
			data: {
				nameDisplay: 'Radar incidence angle (LOS)',
				unit: 'deg',
				description:
					'Local incidence angle of the LOS. Related to position in satellite scene from specific orbital track',
			},
		},
		method: {
			key: 'method',
			data: {
				nameDisplay: 'Interferometric method',
				description: 'Interferometric method used to detect PS/DS point',
			},
		},
		orbit: {
			key: 'orbit',
			data: {
				nameDisplay: 'Orbital trajectory',
				description:
					'Type of orbital trajectory (Ascending / Descending) determining azimuth of LOS',
			},
		},
		point_no: {
			key: 'point_no',
			data: {
				nameDisplay: 'Number of points in the cell',
				description:
					'Number of points in the cell (2 from opposite tracks are minimum)',
			},
		},
		rel: {
			key: 'rel',
			data: {
				nameDisplay: 'Reliability of the estimated velocity',
				description:
					'Reliability of the estimated velocity, the probability that less than 1 % of the displacement measurements are not subject of unwrapping error. Calculated from S0',
			},
		},
		rel_track: {
			key: 'rel_track',
			data: {
				nameDisplay: 'Orbital track',
				description: 'Relative no. of the orbital track',
			},
		},
		s0: {
			key: 's0',
			data: {
				nameDisplay: 'Standard deviation of one measurement',
				unit: 'mm',
				description: 'Standard deviation of one measurement',
			},
		},
		slope: {
			key: 'slope',
			data: {
				nameDisplay: 'Topographic slope gradient (from DEM)',
				description: 'Slope grading calculated from reference DEM',
			},
		},
		svel_fy: {
			key: 'svel_fy',
			data: {
				nameDisplay: 'Standard deviation of annual displacement rate',
				nameInternal: 'Standard deviation of the vel_fy (2015)',
				unit: 'mm/yr',
			},
		},
		svel_last: {
			key: 'svel_last',
			data: {
				nameDisplay:
					'Displacement rate precision/accuracy in the last time series section',
				unit: 'mm/yr',
				description: '',
			},
		},
		svel_ly: {
			key: 'svel_ly',
			data: {
				nameDisplay: 'Standard deviation of annual displacement rate',
				nameInternal: 'Standard deviation of the vel_ly (2020)',
				unit: 'mm/yr',
			},
		},
		svel_my: {
			key: 'svel_my',
			data: {
				nameDisplay: 'Standard deviation of annual displacement rate',
				nameInternal: 'Standard deviation of the vel_my (2017)',
				unit: 'mm/yr',
			},
		},
		svel_v_ly: {
			key: 'svel_v_ly',
			data: {
				nameDisplay: 'Standard deviation of annual displacement rate (2020/1)',
				nameInternal: 'Standard deviation of the vel_v_ly (2020/1)',
				unit: 'mm/yr',
			},
		},
		svel_v_my: {
			key: 'svel_v_my',
			data: {
				nameDisplay: 'Standard deviation of annual displacement rate (2017)',
				nameInternal: 'Standard deviation of the vel_v_my (2017)',
				unit: 'mm/y',
			},
		},
		svel_v: {
			key: 'svel_v',
			data: {
				nameDisplay: 'Standard deviation of annual vertical displacement rate',
				nameInternal: 'Standard deviation of vel_v',
				unit: 'mm/yr',
			},
		},
		svel_ew_ly: {
			key: 'svel_ew_ly',
			data: {
				nameDisplay: 'Standard deviation of annual displacement rate (2020/1)',
				nameInternal: 'Standard deviation of the vel_ew_ly (2020/1)',
				unit: 'mm/yr',
			},
		},
		svel_ew_my: {
			key: 'svel_ew_my',
			data: {
				nameDisplay: 'Standard deviation of annual displacement rate (2017)',
				nameInternal: 'Standard deviation of the vel_ew_my (2017)',
				unit: 'mm/y',
			},
		},
		svel_ew: {
			key: 'svel_ew',
			data: {
				nameDisplay: 'Standard deviation of annual displacement rate',
				nameInternal: 'Standard deviation of vel_ew',
				unit: 'mm/yr',
			},
		},
		t_start: {
			key: 't_start',
			data: {
				nameDisplay: 'Start date of a temporal target',
				description:
					'Date since temporal target has started to be detected (based on reflectivity - relevant for persistent scatterers)',
				inFormat: 'YYYYMMDD.0',
			},
		},
		t_stop: {
			key: 't_stop',
			data: {
				nameDisplay: 'End date of a temporal target',
				description:
					'Date when temporal target ceased to be detected (based on reflectivity - relevant for persistent scatterers)',
				inFormat: 'YYYYMMDD.0',
			},
		},
		td_fy: {
			key: 'td_fy',
			data: {
				nameDisplay: 'Total displacement',
				nameInternal: 'Total displacement over the first year (2015)',
				unit: 'mm',
			},
		},
		td_ly: {
			key: 'td_ly',
			data: {
				nameDisplay: 'Total displacement',
				nameInternal: 'Total displacement over the last year (2020)',
				unit: 'mm',
			},
		},
		td_my: {
			key: 'td_my',
			data: {
				nameDisplay: 'Total displacement',
				nameInternal: 'Total displacement over the middle year (2017)',
				unit: 'mm',
			},
		},
		td_ew_ly: {
			key: 'td_ew_ly',
			data: {
				nameDisplay: 'Total vertical displacement over the last year (2020/1)',
				unit: 'mm',
			},
		},
		td_ew_my: {
			key: 'td_ew_my',
			data: {
				nameDisplay: 'Total vertical displacement over the middle year (2017)',
				unit: 'mm',
			},
		},
		td_v_ly: {
			key: 'td_v_ly',
			data: {
				nameDisplay: 'Total vertical displacement over the last year (2020/1)',
				unit: 'mm',
			},
		},
		td_v_my: {
			key: 'td_v_my',
			data: {
				nameDisplay: 'Total vertical displacement over the middle year (2017)',
				unit: 'mm',
			},
		},
		vel_acc: {
			key: 'vel_acc',
			data: {
				nameDisplay: 'Standard deviation of annual displacement rate',
				unit: 'mm/yr',
				description: 'Standard deviation of annual displacement rate [mm/year]',
			},
		},
		vel_avg: {
			key: 'vel_avg',
			data: {
				nameDisplay: 'Annual displacement rate over entire observation period',
				nameInternal: 'Annual displacement rate',
				unit: 'mm/yr',
				description:
					'Estimated annual displacement rate (average velocity) detected in direction of  satellite’s line-of-sight [mm/year]',
			},
		},
		vel_cum: {
			key: 'vel_cum',
			data: {
				nameDisplay: 'Cumulative displacement',
				unit: 'mm',
				description:
					'Estimated cumulative displacement between the first and last acquisition [mm]',
			},
		},
		vel_fy: {
			key: 'vel_fy',
			data: {
				nameDisplay: 'Annual displacement rate',
				nameInternal: 'Annual displacement rate over the first year (2015)',
				unit: 'mm/yr',
			},
		},
		vel_last: {
			key: 'vel_last',
			data: {
				nameDisplay: 'Displacement rate in the last time series section',
				unit: 'mm/yr',
			},
		},
		vel_ly: {
			key: 'vel_ly',
			data: {
				nameDisplay: 'Annual displacement rate',
				nameInternal: 'Annual displacement rate over the last year (2020)',
				unit: 'mm/yr',
			},
		},
		vel_my: {
			key: 'vel_my',
			data: {
				nameDisplay: 'Annual displacement rate',
				nameInternal: 'Annual displacement rate over the middle year (2017)',
				unit: 'mm/yr',
			},
		},
		vel_ew: {
			key: 'vel_ew',
			data: {
				nameDisplay:
					'Annual horizontal displacement rate over entire observation period',
				nameInternal: 'Annual horizontal displacement rate',
				unit: 'mm/yr',
			},
		},
		vel_ew_ly: {
			key: 'vel_ew_ly',
			data: {
				nameDisplay: 'Annual displacement rate over the last year (2020/1)',
				unit: 'mm/yr',
			},
		},
		vel_ew_my: {
			key: 'vel_ew_my',
			data: {
				nameDisplay: 'Annual displacement rate over the middle year (2017)',
				unit: 'mm/yr',
			},
		},
		vel_v: {
			key: 'vel_v',
			data: {
				nameDisplay:
					'Annual vertical displacement rate over entire observation period',
				nameInternal: 'Annual vertical displacement rate',
				unit: 'mm/yr',
			},
		},
		vel_v_ly: {
			key: 'vel_v_ly',
			data: {
				nameDisplay: 'Annual displacement rate over the last year (2020/1)',
				unit: 'mm/yr',
			},
		},
		vel_v_my: {
			key: 'vel_v_my',
			data: {
				nameDisplay: 'Annual displacement rate over the middle year (2017)',
				unit: 'mm/yr',
			},
		},
		xxx: {
			key: '',
			data: {
				nameDisplay: '',
				unit: '',
				description: '',
			},
		},
	},
};
