import React from 'react';
import PropTypes from 'prop-types';
import {includes as _includes} from 'lodash';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';

import {
	ControlGroup,
	ControlGroupContent,
	ControlGroupLabel,
} from '../components';

import './style.scss';
import {IntervalsLine, LegendHeader, ValuesByColor} from '../../Legend';

class VisualizationControl extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			activeVisualization,
			availableVisualizations,
			visualizations,
			setActiveVisualization,
		} = this.props;

		return (
			<ControlGroup>
				<ControlGroupLabel>Visualizations</ControlGroupLabel>
				<ControlGroupContent>
					<ButtonSwitch
						// className="extended"
						onClick={setActiveVisualization}
						ghost
					>
						{visualizations.map(visualization => (
							<ButtonSwitchOption
								key={visualization.key}
								value={visualization.key}
								active={visualization.key === activeVisualization?.key}
								disabled={
									!_includes(availableVisualizations, visualization.key)
								}
							>
								{visualization.data.nameDisplay}
							</ButtonSwitchOption>
						))}
					</ButtonSwitch>
				</ControlGroupContent>
				{this.renderLegend()}
			</ControlGroup>
		);
	}

	renderLegend() {
		const {attributes, activeStyles} = this.props;
		const styleDefinitions =
			activeStyles?.asMarkers?.data?.definition?.rules?.[0]?.styles;

		if (styleDefinitions) {
			const baseStyle = styleDefinitions[0];
			const attributeStyle = styleDefinitions[1];
			const noDataColor = baseStyle.fill;
			const {attributeKey, ...attributeDefinitions} = attributeStyle;
			const attributeData = attributes[attributeKey]?.data;

			return (
				<div className="insar-Legend">
					<LegendHeader
						title={`${attributeData.nameDisplay} ${
							attributeData.unit ? '[' + attributeData.unit + ']' : ''
						}`}
					/>
					{this.renderLegendContent(attributeDefinitions, noDataColor)}
				</div>
			);
		} else {
			return null;
		}
	}

	renderLegendContent(data, noDataColor) {
		const {
			handleActiveVisualizationFilteredOutValue,
			handleActiveVisualizationFilteredOutNoData,
			handleActiveVisualizationFilteredOutInterval,
			activeVisualization,
		} = this.props;
		const {filteredOutValues, filteredOutIntervals, filteredOutNoData} =
			activeVisualization.data;
		const {attributeClasses, attributeValues} = data;

		if (attributeClasses) {
			return (
				<IntervalsLine
					styleProperty="fill"
					classes={attributeClasses}
					noData={noDataColor}
					longerTails
					disabledIntervals={filteredOutIntervals}
					disabledNoData={filteredOutNoData}
					onNoDataClick={handleActiveVisualizationFilteredOutNoData}
					onIntervalClick={handleActiveVisualizationFilteredOutInterval}
				/>
			);
		} else if (attributeValues) {
			return (
				<ValuesByColor
					data={attributeValues}
					disabledValues={filteredOutValues}
					onClick={handleActiveVisualizationFilteredOutValue}
				/>
			);
		} else {
			return null;
		}
	}
}

VisualizationControl.propTypes = {
	visualizations: PropTypes.array,
	availableVisualizations: PropTypes.array,
	activeVisualization: PropTypes.object,
	activeStyles: PropTypes.object,
	attributes: PropTypes.object,
	setActiveVisualization: PropTypes.func,
	handleActiveVisualizationFilteredOutValue: PropTypes.func,
	handleActiveVisualizationFilteredOutInterval: PropTypes.func,
	handleActiveVisualizationFilteredOutNoData: PropTypes.func,
};

export default VisualizationControl;
