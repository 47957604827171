import React from 'react';
import PropTypes from 'prop-types';
import {includes as _includes, without as _without} from 'lodash';

import Checkbox from '../../Checkbox';
import {
	ControlGroup,
	ControlGroupContent,
	ControlGroupLabel,
} from '../components';

import './style.scss';

class OrbitalTrajectoriesControl extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	onTrajectoryChange(key) {
		const currentActiveKeys = this.props.activeOrbitalTrajectoryKeys;
		let updatedKeys = _includes(currentActiveKeys, key)
			? _without(currentActiveKeys, key)
			: [...currentActiveKeys, key];
		this.props.setActiveOrbitalTrajectories(updatedKeys);
	}

	render() {
		const {
			activeOrbitalTrajectoryKeys,
			activeOrbitalTrajectories,
			orbitalTrajectories,
		} = this.props;

		return (
			<ControlGroup>
				<ControlGroupLabel>Orbital trajectories</ControlGroupLabel>
				<ControlGroupContent>
					<div className="insar-OrbitalTrajectoryControl-checkboxes">
						{orbitalTrajectories.map(trajectory => {
							if (!trajectory?.data?.invisible) {
								const isDisabled =
									activeOrbitalTrajectories?.[0]?.data?.selectionDisabled;

								return (
									<Checkbox
										key={trajectory.key}
										onChange={this.onTrajectoryChange.bind(
											this,
											trajectory.key
										)}
										checked={_includes(
											activeOrbitalTrajectoryKeys,
											trajectory.key
										)}
										disabled={isDisabled}
										name={trajectory.data.nameDisplay}
									/>
								);
							}
						})}
					</div>
				</ControlGroupContent>
			</ControlGroup>
		);
	}
}

OrbitalTrajectoriesControl.propTypes = {
	orbitalTrajectories: PropTypes.array,
	activeOrbitalTrajectoryKeys: PropTypes.array,
	activeOrbitalTrajectories: PropTypes.array,
	setActiveOrbitalTrajectories: PropTypes.func,
};

export default OrbitalTrajectoriesControl;
