// eslint-disable-next-line react/display-name
export default () => (
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="32px"
		height="32px"
		viewBox="0 0 32 32"
		xmlSpace="preserve"
		className="insar-icon"
	>
		<g>
			<path
				className="line"
				d="M22.877,22.878c-0.124,0.124-0.296,0.155-0.385,0.066l-9.438-9.438c-0.088-0.088-0.059-0.262,0.066-0.386l0,0
		c0.125-0.125,0.299-0.155,0.387-0.066l9.438,9.438C23.034,22.58,23.003,22.752,22.877,22.878L22.877,22.878z"
			/>
			<path
				className="line"
				d="M15.288,27.649c0.301-1.854-0.258-3.822-1.686-5.251c-1.432-1.431-3.398-1.988-5.252-1.688L15.288,27.649z"
			/>
			<path
				className="line"
				d="M16.461,10.351L8.015,1.905C7.906,1.796,7.675,1.851,7.5,2.026L2.026,7.5C1.851,7.676,1.797,7.906,1.905,8.014l8.446,8.447
		c0.108,0.108,0.338,0.055,0.515-0.121l5.473-5.474C16.515,10.69,16.569,10.459,16.461,10.351z M10.94,5.957l1.567,1.567
		c0.176,0.176,0.176,0.462,0.001,0.637L10.94,9.729c-0.176,0.176-0.461,0.175-0.637-0.001L8.735,8.161
		C8.56,7.985,8.56,7.701,8.736,7.524l1.568-1.568C10.479,5.781,10.765,5.781,10.94,5.957z M5.95,4.738L7.519,3.17
		c0.174-0.175,0.459-0.175,0.635,0l1.568,1.567C9.897,4.914,9.897,5.2,9.723,5.375L8.155,6.942c-0.177,0.176-0.462,0.176-0.639,0
		L5.95,5.375C5.774,5.199,5.774,4.914,5.95,4.738z M4.738,9.721L3.171,8.153c-0.176-0.176-0.176-0.46,0-0.636l1.568-1.568
		c0.175-0.175,0.46-0.176,0.636,0l1.567,1.568c0.176,0.176,0.176,0.462,0.001,0.637L5.375,9.722
		C5.199,9.898,4.915,9.896,4.738,9.721z M7.524,12.507l-1.567-1.567c-0.175-0.176-0.177-0.459,0-0.636l1.567-1.568
		c0.176-0.175,0.461-0.176,0.637,0l1.568,1.567c0.176,0.176,0.176,0.462,0,0.637l-1.568,1.568C7.985,12.685,7.7,12.683,7.524,12.507
		z M12.516,13.726l-1.568,1.569c-0.176,0.176-0.46,0.174-0.637-0.002l-1.567-1.568c-0.176-0.175-0.177-0.459,0-0.636l1.567-1.568
		c0.176-0.175,0.461-0.176,0.637,0l1.567,1.568C12.69,13.265,12.69,13.551,12.516,13.726z M15.295,10.947l-1.568,1.568
		c-0.176,0.176-0.461,0.176-0.638,0l-1.567-1.568c-0.175-0.175-0.176-0.46,0.001-0.636l1.568-1.568c0.174-0.175,0.459-0.175,0.635,0
		l1.568,1.568C15.47,10.486,15.47,10.772,15.295,10.947z"
			/>
			<path
				className="line"
				d="M34.095,27.985l-8.448-8.448c-0.108-0.108-0.34-0.054-0.515,0.121l-5.474,5.474c-0.176,0.176-0.229,0.406-0.121,0.515
		l8.448,8.448c0.107,0.107,0.338,0.055,0.514-0.122l5.474-5.474C34.148,28.324,34.202,28.093,34.095,27.985z M28.473,23.489
		l1.568,1.568c0.176,0.176,0.176,0.462,0.001,0.637l-1.567,1.568c-0.177,0.176-0.462,0.175-0.638-0.001l-1.568-1.568
		c-0.175-0.175-0.175-0.459,0.001-0.636l1.568-1.567C28.013,23.315,28.298,23.314,28.473,23.489z M23.483,22.271l1.568-1.568
		c0.175-0.174,0.46-0.175,0.635,0l1.568,1.568c0.176,0.176,0.176,0.462,0.001,0.637l-1.568,1.567c-0.176,0.177-0.461,0.176-0.637,0
		l-1.568-1.568C23.308,22.732,23.308,22.448,23.483,22.271z M22.271,27.254l-1.567-1.568c-0.175-0.175-0.176-0.458,0-0.635
		l1.568-1.567c0.176-0.176,0.461-0.177,0.636-0.002l1.567,1.568c0.177,0.176,0.177,0.462,0.001,0.638l-1.568,1.567
		C22.732,27.432,22.448,27.43,22.271,27.254z M25.059,30.04l-1.568-1.568c-0.175-0.175-0.176-0.458,0-0.634l1.568-1.568
		c0.176-0.176,0.461-0.177,0.636-0.002l1.568,1.568c0.176,0.176,0.176,0.462,0,0.638l-1.568,1.568
		C25.519,30.218,25.234,30.217,25.059,30.04z M30.049,31.26l-1.567,1.568c-0.177,0.176-0.461,0.175-0.637-0.002l-1.568-1.567
		c-0.176-0.177-0.176-0.46,0-0.636l1.568-1.568c0.175-0.175,0.46-0.177,0.636-0.001l1.568,1.568
		C30.225,30.799,30.225,31.084,30.049,31.26z M32.829,28.48l-1.568,1.568c-0.177,0.176-0.462,0.175-0.638-0.001l-1.568-1.568
		c-0.176-0.176-0.175-0.46,0.001-0.636l1.568-1.568c0.175-0.175,0.459-0.176,0.635,0l1.568,1.568
		C33.003,28.02,33.003,28.306,32.829,28.48z"
			/>
			<path
				className="line"
				d="M16.903,23.96c-0.176,0.176-0.461,0.175-0.635,0l-4.229-4.229c-0.176-0.175-0.177-0.459-0.001-0.635l7.058-7.058
		c0.176-0.175,0.46-0.175,0.636,0l4.229,4.229c0.175,0.175,0.176,0.46,0,0.636L16.903,23.96z"
			/>
		</g>
	</svg>
);
