export default {
	byKey: {
		asc: {
			key: 'asc',
			data: {
				nameDisplay: 'Ascending',
				tracks: ['142'],
			},
		},
		desc: {
			key: 'desc',
			data: {
				nameDisplay: 'Descending',
				tracks: ['32'],
			},
		},
		compo: {
			key: 'compo',
			data: {
				tracks: ['compo'],
				invisible: true,
				selectionDisabled: true,
			},
		},
	},
};
