/* === BASE === */
const interferometryBase = {
	fill: '#cccccc',
	fillOpacity: 0.85,
	outlineColor: '#555555',
	outlineWidth: 1,
	outlineOpacity: 1,
	size: 15,
};

const interferometryBaseAsPoints = {
	...interferometryBase,
	size: 40,
	outlineWidth: 0,
};

/* === ATTRIBUTES === */

// Points
const velAvg = {
	attributeKey: 'vel_avg',
	attributeClasses: [
		{
			fill: '#b1001d',
			name: '< -50 (Subsidence)',
			interval: [-1000, -50],
			intervalBounds: [true, false],
		},
		{
			fill: '#ca2d2f',
			interval: [-50, -40],
			intervalBounds: [true, false],
		},
		{
			fill: '#e25b40',
			interval: [-40, -30],
			intervalBounds: [true, false],
		},
		{
			fill: '#ffaa00',
			interval: [-30, -20],
			intervalBounds: [true, false],
		},
		{
			fill: '#ffff00',
			interval: [-20, -10],
			intervalBounds: [true, false],
		},
		{
			fill: '#a0f000',
			interval: [-10, 0],
			intervalBounds: [true, false],
		},
		{
			fill: '#4ce600',
			interval: [0, 10],
			intervalBounds: [true, false],
		},
		{
			fill: '#50d48e',
			interval: [10, 20],
			intervalBounds: [true, false],
		},
		{
			fill: '#00c3ff',
			interval: [20, 30],
			intervalBounds: [true, false],
		},
		{
			fill: '#0f80d1',
			interval: [30, 40],
			intervalBounds: [true, false],
		},
		{
			fill: '#004ca8',
			interval: [40, 50],
			intervalBounds: [true, false],
		},
		{
			fill: '#003e8a',
			name: '(Uplift) 50 <',
			interval: [50, 1000],
			intervalBounds: [true, false],
		},
	],
};

const velAvgVert = {
	...velAvg,
	attributeKey: 'vel_v',
};

const velAvgEw = {
	attributeKey: 'vel_ew',
	attributeClasses: [
		{
			fill: '#ca0020',
			name: '< -25 (Westward)',
			interval: [-1000, -25],
			intervalBounds: [true, false],
		},
		{
			fill: '#da3c43',
			interval: [-25, -15],
			intervalBounds: [true, false],
		},
		{
			fill: '#e97867',
			interval: [-20, -15],
			intervalBounds: [true, false],
		},
		{
			fill: '#f5ad8d',
			interval: [-15, -10],
			intervalBounds: [true, false],
		},
		{
			fill: '#f6cbb7',
			interval: [-10, -5],
			intervalBounds: [true, false],
		},
		{
			fill: '#f7f4f3',
			interval: [-5, 0],
			intervalBounds: [true, false],
		},
		{
			fill: '#eff2f3',
			interval: [0, 5],
			intervalBounds: [true, false],
		},
		{
			fill: '#c0dcea',
			interval: [5, 10],
			intervalBounds: [true, false],
		},
		{
			fill: '#9bcae1',
			interval: [10, 15],
			intervalBounds: [true, false],
		},
		{
			fill: '#6baed2',
			interval: [15, 20],
			intervalBounds: [true, false],
		},
		{
			fill: '#3890c1',
			interval: [20, 25],
			intervalBounds: [true, false],
		},
		{
			fill: '#0571b0',
			name: '(Eastward) 25 <',
			interval: [25, 1000],
			intervalBounds: [true, false],
		},
	],
};

const clDyn = {
	attributeKey: 'cl_dyn',
	attributeValues: {
		A: {
			fill: '#641a80',
		},
		DA: {
			fill: '#8c2981',
		},
		ADA: {
			fill: '#b63679',
		},
		DADA: {
			fill: '#de4969',
		},
		ADAD: {
			fill: '#f76f5c',
		},
		DAD: {
			fill: '#fe9f6d',
		},
		AD: {
			fill: '#ffb355',
		},
		D: {
			fill: '#ffff53',
		},
		NO_CLASS: {
			fill: '#e6e6e6',
		},
		CONST_TREND: {
			fill: '#eff6f0',
		},
	},
};

const acceleration = {
	attributeKey: 'accel_lmy',
	attributeClasses: [
		{
			fill: '#d01c8b',
			name: '< -25',
			interval: [-1000, -25],
			intervalBounds: [true, false],
		},
		{
			fill: '#dc54a8',
			interval: [-25, -20],
			intervalBounds: [true, false],
		},
		{
			fill: '#e88cc5',
			interval: [-20, -15],
			intervalBounds: [true, false],
		},
		{
			fill: '#f2bcdd',
			interval: [-15, -10],
			intervalBounds: [true, false],
		},
		{
			fill: '#f4d4e8',
			interval: [-10, -5],
			intervalBounds: [true, false],
		},
		{
			fill: '#f6f2f5',
			interval: [-5, 0],
			intervalBounds: [true, false],
		},
		{
			fill: '#f0f3ec',
			interval: [0, 5],
			intervalBounds: [true, false],
		},
		{
			fill: '#d5ebba',
			interval: [5, 10],
			intervalBounds: [true, false],
		},
		{
			fill: '#bee390',
			interval: [10, 15],
			intervalBounds: [true, false],
		},
		{
			fill: '#9bd36c',
			interval: [15, 20],
			intervalBounds: [true, false],
		},
		{
			fill: '#74c049',
			interval: [20, 25],
			intervalBounds: [true, false],
		},
		{
			fill: '#4dac26',
			name: '> 25',
			interval: [25, 1000],
			intervalBounds: [true, false],
		},
	],
};

const accelerationVert = {
	...acceleration,
	attributeKey: 'acc_v_lmy',
};

const accelerationEw = {
	...acceleration,
	attributeKey: 'acc_ew_lmy',
};

const accelerationVertgLastYear = {
	...acceleration,
	attributeKey: 'cl_accel_l',
};

export default {
	interferometryBase,
	interferometryBaseAsPoints,

	velAvg,
	velAvgVert,
	velAvgEw,
	clDyn,
	acceleration,
	accelerationVert,
	accelerationEw,
	accelerationVertgLastYear,
};
