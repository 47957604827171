import React from 'react';
import PropTypes from 'prop-types';

import InterferometryChart from '../../InterferometryChart';
import AttributesPanel from '../../AttributesPanel';
import InterferometryControls from '../../InterferometryControls';

class Interferometry extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const props = this.props;

		return (
			<div className="insar-ControlPanel-section-content">
				<InterferometryControls />

				<div className="insar-ControlPanel-data-wrapper">
					{props.feature ? (
						<>
							{props.chartUnavailable ? null : (
								<InterferometryChart
									attributes={this.props.feature.properties}
								/>
							)}
							<AttributesPanel
								featureProperties={this.props.feature.properties}
							/>
						</>
					) : (
						<p className="centered">Select point to display statistics...</p>
					)}
				</div>
				<div className="insar-ControlPanel-copyright">
					Copernicus Emergency Management Service (© 2021 European Union),
					[EMSN091] Manila: Ground Deformation Map
				</div>
			</div>
		);
	}
}

Interferometry.propTypes = {
	chartUnavailable: PropTypes.bool,
	feature: PropTypes.object,
};

export default Interferometry;
