import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		types: Select.insar.types.getAll(state),
		activeTypeKey: Select.insar.types.getActiveKey(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setActiveType: key => {
			dispatch(Action.insar.types.setActive(key));
			dispatch(Action.insar.useInterferometryMapData());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
