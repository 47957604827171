import backgroundLayers from './layers/backgroundLayers';
import vectorLayers from './layers/vectorLayers';

export default {
	key: '5f02bf8f-e034-45f8-81b0-5266fd54af8a',
	data: {
		state: {
			insar: {
				types: {
					activeKey: 'los',
				},
				orbitalTrajectories: {
					activeKeys: ['asc'],
				},
				visualizations: {
					activeKey: 'displacement',
				},
			},
			components: {
				introOverlay: {
					open: true,
				},
				controlPanel: {
					minimised: false,
					chartExpanded: false,
				},
			},
			maps: {
				activeMapKey: 'interferometry',
				maps: {
					interferometry: {
						key: 'interferometry',
						data: {
							backgroundLayer: backgroundLayers.imagery, // TODO add later
							layers: [vectorLayers.interferometry], // TODO add later
							view: {
								boxRange: 200000,
								center: {
									lat: 14.78,
									lon: 122,
								},
							},
							viewLimits: {
								boxRangeRange: [400, 5000000],
							},
						},
					},
				},
			},
			selections: {
				activeKey: 'interferometry',
				data: [
					{
						key: 'interferometry',
						data: {
							color: '#00fffe',
							hoveredColor: '#1efbff',
						},
					},
				],
			},
		},
	},
};
