import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {MapControls, DeckGlMap} from '@gisatcz/ptr-maps';
import InterferometryMap from './InterferometryMap';
import MapWrapper from '../MapWrapper';
import InerferometryMapTooltip from '../MapTooltip';

import './style.scss';

class MainMap extends React.PureComponent {
	render() {
		const classes = classnames('insar-MainMap', {
			'full-width': this.props.fullWidth,
			'is-chartExpanded': this.props.chartExpanded,
		});

		return (
			<div className="insar-MainMapPanel">
				<div className={classes}>
					<InterferometryMap
						mapComponent={DeckGlMap}
						stateMapKey={this.props.activeMapKey}
						wrapper={MapWrapper}
						Tooltip={InerferometryMapTooltip}
					>
						<MapControls zoomOnly levelsBased />
					</InterferometryMap>
				</div>
			</div>
		);
	}
}

MainMap.propTypes = {
	activeMapKey: PropTypes.string,
	chartExpanded: PropTypes.bool,
	fullWidth: PropTypes.bool,
};

export default MainMap;
