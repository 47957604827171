import {connect, setRecomputeState} from '@gisatcz/ptr-state';
import {PresentationMap} from '@gisatcz/ptr-maps';
import Action from '../../state/Action';
import Select from '../../state/Select';

const mapStateToProps = (state, ownProps) => {
	setRecomputeState(state);

	return {
		backgroundLayer: Select.maps.getMapBackgroundLayer(ownProps.stateMapKey),
		layers: Select.maps.getMapLayers(ownProps.stateMapKey),
		view: Select.maps.getViewByMapKey(state, ownProps.stateMapKey),
		viewLimits: Select.maps.getViewLimitsByMapKey(state, ownProps.stateMapKey),
		mapKey: ownProps.stateMapKey,
		viewport: Select.maps.getViewportByMapKey(state, ownProps.stateMapKey),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: (mapWidth, mapHeight) => {
			dispatch(
				Action.maps.setMapViewport(ownProps.stateMapKey, mapWidth, mapHeight)
			);
		},
		onResize: (mapWidth, mapHeight) => {
			dispatch(
				Action.maps.setMapViewport(ownProps.stateMapKey, mapWidth, mapHeight)
			);
		},
		onViewChange: update => {
			dispatch(Action.maps.updateMapAndSetView(ownProps.stateMapKey, update));
		},
		onPropViewChange: (update, mapWidth, mapHeight) => {
			dispatch(
				Action.maps.setMapViewport(ownProps.stateMapKey, mapWidth, mapHeight)
			);
		},
		onLayerClick: (mapKey, layerKey, selectedFeatureKeys) => {
			dispatch(
				Action.maps.setLayerSelectedFeatureKeys(
					ownProps.stateMapKey,
					layerKey,
					selectedFeatureKeys
				)
			);
			dispatch(
				Action.insar.useInterferometryPointStatistics(selectedFeatureKeys)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PresentationMap);
