import fragments from './fragments';

const displacementLosAsMarkers = {
	key: 'displacementLosAsMarkers',
	data: {
		nameDisplay: 'Displacement',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBase, fragments.velAvg],
				},
			],
		},
	},
};

const displacementVertgAsMarkers = {
	...displacementLosAsMarkers,
	key: 'displacementVertgAsMarkers',
};

const displacementVertAsMarkers = {
	key: 'displacementVertAsMarkers',
	data: {
		nameDisplay: 'Displacement',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBase, fragments.velAvgVert],
				},
			],
		},
	},
};

const displacementEwAsMarkers = {
	key: 'displacementEwAsMarkers',
	data: {
		nameDisplay: 'Displacement',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBase, fragments.velAvgEw],
				},
			],
		},
	},
};

const displacementLosAsPoints = {
	key: 'displacementLosAsPoints',
	data: {
		nameDisplay: 'Displacement',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBaseAsPoints, fragments.velAvg],
				},
			],
		},
	},
};

const displacementVertgAsPoints = {
	...displacementLosAsPoints,
	key: 'displacementVertgAsPoints',
};

const displacementVertAsPoints = {
	key: 'displacementVertAsPoints',
	data: {
		nameDisplay: 'Displacement',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBaseAsPoints, fragments.velAvgVert],
				},
			],
		},
	},
};

const displacementEwAsPoints = {
	key: 'displacementEwAsPoints',
	data: {
		nameDisplay: 'Displacement',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBaseAsPoints, fragments.velAvgEw],
				},
			],
		},
	},
};

const trendDynamicsLosAsMarkers = {
	key: 'trendDynamicsLosAsMarkers',
	data: {
		nameDisplay: 'Trend dynamics',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBase, fragments.clDyn],
				},
			],
		},
	},
};

const trendDynamicsVertgAsMarkers = {
	...trendDynamicsLosAsMarkers,
	key: 'trendDynamicsVertgAsMarkers',
};

const trendDynamicsLosAsPoints = {
	key: 'trendDynamicsLosAsPoints',
	data: {
		nameDisplay: 'Trend dynamics',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBaseAsPoints, fragments.clDyn],
				},
			],
		},
	},
};

const trendDynamicsVertgAsPoints = {
	...trendDynamicsLosAsPoints,
	key: 'trendDynamicsVertgAsPoints',
};

const accelerationVertgAsMarkers = {
	key: 'accelerationVertgAsMarkers',
	data: {
		nameDisplay: 'Acceleration (2000 - 2017)',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBase, fragments.acceleration],
				},
			],
		},
	},
};

const accelerationVertgAsPoints = {
	key: 'accelerationVertgAsPoints',
	data: {
		nameDisplay: 'Acceleration (2000 - 2017)',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						fragments.interferometryBaseAsPoints,
						fragments.acceleration,
					],
				},
			],
		},
	},
};

const accelerationVertAsMarkers = {
	key: 'accelerationVertAsMarkers',
	data: {
		nameDisplay: 'Acceleration (2000 - 2017)',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBase, fragments.accelerationVert],
				},
			],
		},
	},
};

const accelerationVertAsPoints = {
	key: 'accelerationVertAsPoints',
	data: {
		nameDisplay: 'Acceleration (2000 - 2017)',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						fragments.interferometryBaseAsPoints,
						fragments.accelerationVert,
					],
				},
			],
		},
	},
};

const accelerationEwAsMarkers = {
	key: 'accelerationEwAsMarkers',
	data: {
		nameDisplay: 'Acceleration (2000 - 2017)',
		description: '',
		definition: {
			rules: [
				{
					styles: [fragments.interferometryBase, fragments.accelerationEw],
				},
			],
		},
	},
};

const accelerationEwAsPoints = {
	key: 'accelerationEwAsPoints',
	data: {
		nameDisplay: 'Acceleration (2000 - 2017)',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						fragments.interferometryBaseAsPoints,
						fragments.accelerationEw,
					],
				},
			],
		},
	},
};

const accelerationLastYearVertgAsMarkers = {
	key: 'accelerationLastYearVertgAsMarkers',
	data: {
		nameDisplay: 'Acceleration of the last year',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						fragments.interferometryBase,
						fragments.accelerationVertgLastYear,
					],
				},
			],
		},
	},
};

const accelerationLastYearVertgAsPoints = {
	key: 'accelerationLastYearVertgAsPoints',
	data: {
		nameDisplay: 'Acceleration of the last year',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						fragments.interferometryBaseAsPoints,
						fragments.accelerationVertgLastYear,
					],
				},
			],
		},
	},
};

export default {
	byKey: {
		displacementLosAsMarkers,
		displacementLosAsPoints,
		displacementVertgAsMarkers,
		displacementVertgAsPoints,
		displacementVertAsMarkers,
		displacementVertAsPoints,
		displacementEwAsMarkers,
		displacementEwAsPoints,

		trendDynamicsLosAsMarkers,
		trendDynamicsLosAsPoints,
		trendDynamicsVertgAsMarkers,
		trendDynamicsVertgAsPoints,

		accelerationVertgAsMarkers,
		accelerationVertgAsPoints,
		accelerationVertAsMarkers,
		accelerationVertAsPoints,
		accelerationEwAsMarkers,
		accelerationEwAsPoints,

		accelerationLastYearVertgAsMarkers,
		accelerationLastYearVertgAsPoints,
	},
};
