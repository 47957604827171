import {connect} from '@gisatcz/ptr-state';

import Select from '../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		open: Select.components.get(state, 'loadingOverlay', 'open'),
	};
};

export default connect(mapStateToProps)(presentation);
