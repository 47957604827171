import {connect} from '@gisatcz/ptr-state';

import Select from '../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		attributes: Select.insar.getAttributesForTooltip(state),
	};
};

export default connect(mapStateToProps)(presentation);
