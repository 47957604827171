import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		orbitalTrajectories: Select.insar.orbitalTrajectories.getAll(state),
		activeOrbitalTrajectoryKeys:
			Select.insar.orbitalTrajectories.getActiveKeys(state),
		activeOrbitalTrajectories:
			Select.insar.orbitalTrajectories.getActiveModels(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setActiveOrbitalTrajectories: keys => {
			dispatch(Action.insar.orbitalTrajectories.setActiveKeys(keys));
			dispatch(Action.insar.useInterferometryMapData());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
