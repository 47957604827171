import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';

import './style.scss';

class ControlButton extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onStatusChange = this.onStatusChange.bind(this);
	}

	onStatusChange() {
		this.props.onStatusChange(!this.props.minimised);
	}

	render() {
		const props = this.props;

		const classes = classnames('insar-ControlButton', {
			'is-minimised': props.minimised,
			'is-chartExpanded': props.chartExpanded,
		});

		return (
			<Button
				onClick={this.onStatusChange}
				side="right"
				className={classes}
				icon="chevron-right"
			/>
		);
	}
}

ControlButton.propTypes = {
	minimised: PropTypes.bool,
	chartExpanded: PropTypes.bool,
	onStatusChange: PropTypes.func,
};

export default ControlButton;
