export default {
	byKey: {
		displacement: {
			key: 'displacement',
			data: {
				// nameDisplay: (
				// 	<>
				// 		<div>Displacement</div>
				// 	</>
				// ),
				nameDisplay: 'Displacement',
				stylesByType: {
					los: {
						asMarkers: 'displacementLosAsMarkers',
						asPoints: 'displacementLosAsPoints',
					},
					vertg: {
						asMarkers: 'displacementVertgAsMarkers',
						asPoints: 'displacementVertgAsPoints',
					},
					vert: {
						asMarkers: 'displacementVertAsMarkers',
						asPoints: 'displacementVertAsPoints',
					},
					ew: {
						asMarkers: 'displacementEwAsMarkers',
						asPoints: 'displacementEwAsPoints',
					},
				},
				orderingByType: {
					los: {
						attribute: 'vel_avg',
						order: 'asc',
						type: 'abs',
					},
					vertg: {
						attribute: 'vel_avg',
						order: 'asc',
						type: 'abs',
					},
					vert: {
						attribute: 'vel_v',
						order: 'asc',
						type: 'abs',
					},
					ew: {
						attribute: 'vel_ew',
						order: 'asc',
						type: 'abs',
					},
				},
			},
		},
		trendDynamics: {
			key: 'trendDynamics',
			data: {
				// nameDisplay: (
				// 	<>
				// 		<div>Trend dynamics</div>
				// 	</>
				// ),
				nameDisplay: 'Trend dynamics',
				stylesByType: {
					los: {
						asMarkers: 'trendDynamicsLosAsMarkers',
						asPoints: 'trendDynamicsLosAsPoints',
					},
					vertg: {
						asMarkers: 'trendDynamicsVertgAsMarkers',
						asPoints: 'trendDynamicsVertgAsPoints',
					},
				},
				orderingByType: {
					los: {
						attribute: 'cl_dyn',
						order: 'asc',
						type: 'trendDynamics',
					},
					vertg: {
						attribute: 'cl_dyn',
						order: 'asc',
						type: 'trendDynamics',
					},
				},
			},
		},
		acceleration: {
			key: 'acceleration',
			data: {
				// nameDisplay: (
				// 	<>
				// 		<div>Acceleration 2020 vs. 2017</div>
				// 	</>
				// ),
				nameDisplay: 'Acceleration 2020 vs. 2017',
				stylesByType: {
					vertg: {
						asMarkers: 'accelerationVertgAsMarkers',
						asPoints: 'accelerationVertgAsPoints',
					},
					vert: {
						asMarkers: 'accelerationVertAsMarkers',
						asPoints: 'accelerationVertAsPoints',
					},
					ew: {
						asMarkers: 'accelerationEwAsMarkers',
						asPoints: 'accelerationEwAsPoints',
					},
				},
				orderingByType: {
					vertg: {
						attribute: 'accel_lmy',
						order: 'asc',
						type: 'abs',
					},
					vert: {
						attribute: 'acc_v_lmy',
						order: 'asc',
						type: 'abs',
					},
					ew: {
						attribute: 'acc_ew_lmy',
						order: 'asc',
						type: 'abs',
					},
				},
			},
		},
		// accelerationLastYear: {
		// 	key: 'accelerationLastYear',
		//
		// 	data: {
		// 		nameDisplay: (
		// 			<>
		// 				<div>Acceleration</div> <span>of the last year</span>
		// 			</>
		// 		),
		// 		stylesByType: {
		// 			vertg: {
		// 				asMarkers: 'accelerationLastYearVertgAsMarkers',
		// 				asPoints: 'accelerationLastYearVertgAsPoints',
		// 			},
		// 		},
		// 		orderingByType: {
		// 			vertg: {
		// 				attribute: 'cl_accel_l',
		// 				order: 'asc',
		// 				type: 'abs',
		// 			},
		// 		},
		// 	},
		// },
	},
};
