import PropTypes from 'prop-types';
import './style.scss';

const Warning = ({children}) => {
	return (
		<div className="insar-Warning">
			<div className="insar-Warning-icon" />
			<div className="insar-Warning-text">{children}</div>
		</div>
	);
};

Warning.propTypes = {
	children: PropTypes.node,
};

export default Warning;
