export default {
	byKey: {
		acceleration: {
			key: 'acceleration',
			data: {
				nameDisplay: 'Acceleration (2017-2021)',
				attributes: ['acc_v_lmy'],
			},
		},
		acceleration_we: {
			key: 'acceleration_ew',
			data: {
				nameDisplay: 'Acceleration (2017-2021)',
				attributes: ['acc_ew_lmy'],
			},
		},
		annual_trends: {
			key: 'annual_trends',
			data: {
				nameDisplay: 'Annual trends',
				attributes: ['accel_lmy'],
			},
		},
		annual_trends_2020: {
			key: 'annual_trends_2020',
			data: {
				nameDisplay: '2020',
				nameInternal: 'Annual trends 2020',
				subset: true,
				attributes: ['vel_ly', 'td_ly', 'svel_ly'],
			},
		},
		annual_trends_2017: {
			key: 'annual_trends_2017',
			data: {
				nameDisplay: '2017',
				nameInternal: 'Annual trends 2020',
				subset: true,
				attributes: ['vel_my', 'td_my', 'svel_my'],
			},
		},
		annual_trends_2015: {
			key: 'annual_trends_2015',
			data: {
				nameDisplay: '2015',
				nameInternal: 'Annual trends 2015',
				subset: true,
				attributes: ['vel_fy', 'td_fy', 'svel_fy'],
			},
		},
		ids: {
			key: 'ids',
			data: {
				attributes: ['gid', 'id_global'],
			},
		},
		ids_compo: {
			key: 'ids_compo',
			data: {
				attributes: ['gid', 'point_no'],
			},
		},
		height: {
			key: 'height',
			data: {
				nameDisplay: 'Height',
				attributes: ['h_ref', 'h_lid1m', 'h_pamz10m', 'h_cop30m'],
			},
		},
		metadata: {
			key: 'metadata',
			data: {
				nameDisplay: 'Metadata',
				attributes: [
					'rel_track',
					'orbit',
					'inc_ang',
					'az_ang',
					// 'aspect',
					// 'slope',
					// 'method',
				],
			},
		},
		psi_estimations: {
			key: 'psi_estimations',
			data: {
				nameDisplay: 'PSI estimations',
				attributes: ['vel_avg', 'vel_acc', 'vel_cum', 's0'],
			},
		},
		psi_reliability: {
			key: 'psi_reliability',
			data: {
				nameDisplay: 'PSI reliability',
				attributes: ['coh', 'rel'],
			},
		},
		psi_trend_estimation: {
			key: 'psi_trend_estimation',
			data: {
				nameDisplay: 'PSI trend estimation',
				attributes: ['t_start', 't_stop', 'cl_prg', 'cl_dyn'],
			},
		},
		trend_last_period: {
			key: 'trend_last_period',
			data: {
				nameDisplay: 'Trend of the last period',
				attributes: ['cl_accel_l', 'vel_last', 'svel_last'],
			},
		},
		vert_2017: {
			key: 'vert_2017',
			data: {
				nameDisplay: '2017',
				attributes: ['vel_v_my', 'td_v_my', 'svel_v_my'],
			},
		},
		vert_2017_2021: {
			key: 'vert_2017_2021',
			data: {
				nameDisplay: '2017 - 2021',
				attributes: ['vel_v', 'svel_v'],
			},
		},
		vert_2020_1: {
			key: 'vert_2020_1',
			data: {
				nameDisplay: '2020/1',
				attributes: ['vel_v_ly', 'td_v_ly', 'svel_v_ly'],
			},
		},
		vert_2017_ew: {
			key: 'vert_2017_ew',
			data: {
				nameDisplay: '2017',
				attributes: ['vel_ew_my', 'td_ew_my', 'svel_ew_my'],
			},
		},
		vert_2017_2021_ew: {
			key: 'vert_2017_2021_ew',
			data: {
				nameDisplay: '2017 - 2021',
				attributes: ['vel_ew', 'svel_ew'],
			},
		},
		vert_2020_1_ew: {
			key: 'vert_2020_1_ew',
			data: {
				nameDisplay: '2020/1',
				attributes: ['vel_ew_ly', 'td_ew_ly', 'svel_ew_ly'],
			},
		},
	},
};
