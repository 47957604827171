import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {map as _map, find as _find} from 'lodash';
import {
	Button,
	ButtonSwitch,
	ButtonSwitchOption,
	Icon,
} from '@gisatcz/ptr-atoms';
import Checkbox from '../Checkbox';
import MapDirectionIcon from '../MapDirectionIcon';
import Warning from '../Warning';

import './style.scss';

class MapWrapper extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};

		this.changeState = this.changeState.bind(this);
		this.switchBackground = this.switchBackground.bind(this);
	}

	changeState() {
		this.setState({
			open: !this.state.open,
		});
	}

	switchBackground(key) {
		this.props.setBackgroundLayer(this.props.availableBackgroundLayers[key]);
	}

	handleWmsLayers(key) {
		const isPresent = this.isWmsLayerPresent(key);
		if (isPresent) {
			this.props.removeWmsLayer(key);
		} else {
			this.props.addWmsLayer(key);
		}
	}

	isWmsLayerPresent(key) {
		const {layers} = this.props;
		return _find(layers, layer => layer.key === key);
	}

	render() {
		const props = this.props;
		const {
			availableBackgroundLayers,
			activeOrbitalTrajectoryKeys,
			activeTypeKey,
			dataMode,
		} = props;

		const classes = classnames('insar-MapWrapper', {
			open: this.state.open,
			'full-width': this.props.fullWidth,
			'is-chartExpanded': this.props.chartExpanded,
		});

		const backgroundLayerKey = props.backgroundLayer?.key;

		// Button is wrapped in ptr-map-controls just for styling.
		// TODO create LayerControlGroup components when adding wms layers
		return (
			<div className="ptr-map-wrapper">
				{dataMode === 'decimated' ? (
					<Warning>Only the data subset is displayed</Warning>
				) : null}
				{activeTypeKey === 'los' && activeOrbitalTrajectoryKeys.length
					? this.renderDirectionIcons(
							props.backgroundLayer,
							activeOrbitalTrajectoryKeys
					  )
					: null}

				<div className="ptr-map-controls insar-LayersButton">
					<div className="control">
						<Button icon="layers" onClick={this.changeState} />
					</div>
				</div>

				<div className="insar-MapAttribution">
					<a href="https://deck.gl/" target="_blank" rel="noreferrer noopener">
						deck.gl
					</a>{' '}
					| Background map:{' '}
					{availableBackgroundLayers[backgroundLayerKey].attribution}
				</div>

				<div className={classes}>
					<div className="insar-MapWrapper-header" onClick={this.changeState}>
						<div>
							<Icon icon="layers" />
							<span>Layers</span>
						</div>
						<Button
							icon="times"
							invisible
							small
							circular
							onClick={this.changeState}
						/>
					</div>
					<div className="insar-MapWrapper-content">
						<div className="insar-LayersControlGroup">
							<div className="insar-LayersControlGroup-header">
								JRCs Global Surface Water
							</div>
							<div className="insar-LayersControlGroup-content">
								{this.renderWmsLayers()}
							</div>
						</div>
						<div className="insar-LayersControlGroup">
							<div className="insar-LayersControlGroup-header">
								Background layers
							</div>
							<div className="insar-LayersControlGroup-content">
								{this.renderBackgroundLayers()}
							</div>
						</div>
					</div>
				</div>
				{this.props.children}
			</div>
		);
	}

	renderDirectionIcons(backgroundLayer, orbitalTrajectories) {
		const backgroundTheme = backgroundLayer.theme;
		const mapIconClasses = classnames('insar-MapDirectionIcons', {
			'ptr-light': backgroundTheme === 'dark',
		});

		return (
			<div className={mapIconClasses}>
				{orbitalTrajectories.map(trajectoryKey => (
					<MapDirectionIcon key={trajectoryKey} trajectoryKey={trajectoryKey} />
				))}
			</div>
		);
	}

	renderBackgroundLayers() {
		const activeLayerKey = this.props.backgroundLayer?.key;
		const {availableBackgroundLayers} = this.props;

		const options = _map(availableBackgroundLayers, layer => (
			<ButtonSwitchOption
				key={layer.key}
				value={layer.key}
				active={layer.key === activeLayerKey}
			>
				{layer.name}
			</ButtonSwitchOption>
		));

		return (
			<ButtonSwitch onClick={this.switchBackground} ghost>
				{options}
			</ButtonSwitch>
		);
	}

	renderWmsLayers() {
		const {availableWmsLayers} = this.props;

		return _map(availableWmsLayers, wmsLayer => {
			const active = this.isWmsLayerPresent(wmsLayer.key);
			return (
				<React.Fragment key={wmsLayer.key}>
					<Checkbox
						onChange={this.handleWmsLayers.bind(this, wmsLayer.key)}
						checked={active}
						name={wmsLayer.name}
					/>
					{this.renderWmsLegend(wmsLayer.key, active)}
				</React.Fragment>
			);
		});
	}

	renderWmsLegend(key, active) {
		const legendClasses = classnames('insar-WmsLayerLegend', {
			'is-open': active,
		});

		if (key === 'occurrence') {
			return (
				<div className={legendClasses}>
					<div className="insar-WmsLayerLegend-content" id="occurrence-legend">
						<div className="insar-WmsLayerLegend-bar" />
						<div className="insar-WmsLayerLegend-labels">
							<span>Sometimes water (&gt; 0 %)</span>
							<span>Always water (100 %)</span>
						</div>
					</div>
				</div>
			);
		} else if (key === 'change') {
			return (
				<div className={legendClasses}>
					<div className="insar-WmsLayerLegend-content" id="change-legend">
						<div className="insar-WmsLayerLegend-bar" />
						<div className="insar-WmsLayerLegend-labels">
							<span>Decrease</span>
							<span>No change</span>
							<span>Increase</span>
						</div>
					</div>
				</div>
			);
		} else if (key === 'seasonality') {
			return (
				<div className={legendClasses}>
					<div className="insar-WmsLayerLegend-content" id="seasonality-legend">
						<div className="insar-WmsLayerLegend-bar" />
						<div className="insar-WmsLayerLegend-labels">
							<span>Seasonal</span>
							<span>Permanent</span>
						</div>
					</div>
				</div>
			);
		} else if (key === 'recurrence') {
			return (
				<div className={legendClasses}>
					<div className="insar-WmsLayerLegend-content" id="recurrence-legend">
						<div className="insar-WmsLayerLegend-bar" />
						<div className="insar-WmsLayerLegend-labels">
							<span>&gt; 0 %</span>
							<span>100 %</span>
						</div>
					</div>
				</div>
			);
		} else if (key === 'transitions') {
			return (
				<div className={legendClasses}>
					<div className="insar-WmsLayerLegend-list">
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#0000DD'}} />
							<span>Permanent</span>
						</div>
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#22B14C'}} />
							<span>New permanent</span>
						</div>
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#93073E'}} />
							<span>Lost permanent</span>
						</div>
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#99D9EA'}} />
							<span>Seasonal</span>
						</div>
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#B5E61D'}} />
							<span>New seasonal</span>
						</div>
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#EBB4BB'}} />
							<span>Lost seasonal</span>
						</div>
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#FF8B37'}} />
							<span>Seasonal to permanent</span>
						</div>
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#FFDD66'}} />
							<span>Permanent to seasonal</span>
						</div>
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#7F7F7F'}} />
							<span>Ephemeral permanent</span>
						</div>
						<div className="insar-WmsLayerLegend-item">
							<div style={{background: '#ACACAC'}} />
							<span>Ephemeral seasonal</span>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

MapWrapper.propTypes = {
	activeOrbitalTrajectoryKeys: PropTypes.array,
	activeTypeKey: PropTypes.string,
	addWmsLayer: PropTypes.func,
	dataMode: PropTypes.string,
	layers: PropTypes.array,
	removeWmsLayer: PropTypes.func,
	availableBackgroundLayers: PropTypes.object,
	availableWmsLayers: PropTypes.object,
	backgroundLayer: PropTypes.object,
	fullWidth: PropTypes.bool,
	children: PropTypes.node,
	chartExpanded: PropTypes.bool,
	setBackgroundLayer: PropTypes.func,
};

export default MapWrapper;
