import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

class LoadingOverlay extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			status: 'closed',
		};
	}

	componentDidUpdate(prevProps) {
		const prevOpen = prevProps?.open;
		const nextOpen = this.props.open;

		if (!prevOpen && nextOpen) {
			this.setState({status: 'opening'});
			setTimeout(() => {
				this.setState({status: 'open'});
			}, 1);
		} else if (prevOpen && !nextOpen) {
			this.setState({status: 'closing'});
			setTimeout(() => {
				this.setState({status: 'closed'});
			}, 300);
		}
	}

	render() {
		const classes = classnames('insar-LoadingOverlay', {
			'is-open': this.state.status === 'open',
			'is-opening': this.state.status === 'opening',
			'is-closing': this.state.status === 'closing',
		});

		return (
			<div className={classes}>
				<div className="insar-LoadingOverlayLoader">Loading data...</div>
			</div>
		);
	}
}

LoadingOverlay.propTypes = {
	open: PropTypes.bool,
};

export default LoadingOverlay;
