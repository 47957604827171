import {commonActionTypes} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

const specificActionTypes = utils.deepKeyMirror({
	INSAR: {
		TYPES: {
			SET_ACTIVE_KEY: null,
			UPDATE_STORE: null,
		},
		ORBITAL_TRAJECTORIES: {
			SET_ACTIVE_KEYS: null,
			UPDATE_STORE: null,
		},
		VISUALIZATIONS: {
			SET_ACTIVE_KEY: null,
			SET_PROPERTY: null,
			UPDATE_STORE: null,
		},
		INTERFEROMETRY_DATA: {
			ADD_BY_TYPE_BY_FEATURE_KEY: null,
			ADD_BY_TYPE_BY_TRACK_KEY: null,
		},
	},
});

export default {
	...commonActionTypes,
	...specificActionTypes,
};
