import classnames from 'classnames';
import Satellite from '../../icons/satellite';
import Direction from '../../icons/direction';

import './style.scss';
import PropTypes from 'prop-types';

const MapDirectionIcon = ({trajectoryKey}) => {
	let text;

	if (trajectoryKey === 'asc') {
		text = 'LOS ascending';
	} else {
		text = 'LOS descending';
	}

	let classes = classnames('insar-MapIcon-wrap', {
		descending: trajectoryKey === 'desc',
	});

	return (
		<div className="insar-MapIcon">
			<div className={classes}>
				<Satellite />
				<Direction />
			</div>
			<div className="insar-MapIcon-title">{text}</div>
		</div>
	);
};

MapDirectionIcon.propTypes = {
	trajectoryKey: PropTypes.string,
};

export default MapDirectionIcon;
