import PropTypes from 'prop-types';

export const ControlGroup = ({children}) => (
	<div className="insar-ControlGroup">{children}</div>
);

ControlGroup.propTypes = {
	children: PropTypes.node,
};

export const ControlGroupLabel = props => {
	const {children, className, ...restProps} = props;
	const classes = `insar-ControlGroupLabel ${className}`;
	return (
		<span className={classes} {...restProps}>
			{children}
		</span>
	);
};
ControlGroupLabel.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export const ControlGroupContent = ({children}) => (
	<div className="insar-ControlGroupContent">{children}</div>
);

ControlGroupContent.propTypes = {
	children: PropTypes.node,
};
