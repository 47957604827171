import {commonActions} from '@gisatcz/ptr-state';
import {intersection as _intersection, includes as _includes} from 'lodash';
import utils from '../../../utils';
import ActionTypes from '../../../constants/ActionTypes';
import Select from '../../Select';
import orbitalTrajectoriesActions from '../OrbitalTrajectories/actions';
import visualizationsActions from '../Visualizations/actions';
import Action from '../../Action';

const setActiveKey = commonActions.setActiveKey(ActionTypes.INSAR.TYPES);

const updateStateFromView = commonActions.updateSubstateFromView(
	ActionTypes.INSAR.TYPES
);
const updateStore = commonActions.updateStore(
	Select.insar.types.getSubstate,
	ActionTypes.INSAR.TYPES
);

const setActive = key => {
	return (dispatch, getState) => {
		const state = getState();

		const prevType = Select.insar.types.getActiveKey(state);

		const prevGroup = Select.insar.types.getGroupByActive(state);
		const nextGroup = Select.insar.types.getGroupByKey(state, key);

		const activeOrbitalTrajectoryKeys =
			Select.insar.orbitalTrajectories.getActiveKeys(state) || [];
		const nextAvailableOrbitalTrajectoryKeys =
			Select.insar.types.getAvailableOrbitalTrajectoryKeys(state, key) || [];

		const activeOrbitalTrajectoryAmongAvailable = _intersection(
			activeOrbitalTrajectoryKeys,
			nextAvailableOrbitalTrajectoryKeys
		);

		const activeVisualization = Select.insar.visualizations.getActiveKey(state);
		const nextAvailableVisualizations =
			Select.insar.types.getAvailableVisualizations(state, key);
		const activeVisualizationAmongAvailable = _includes(
			nextAvailableVisualizations,
			activeVisualization
		);

		dispatch(setActiveKey(key));

		if (
			prevGroup !== nextGroup &&
			!activeOrbitalTrajectoryAmongAvailable.length
		) {
			// set first available trajectory as active
			dispatch(
				orbitalTrajectoriesActions.setActiveKeys([
					nextAvailableOrbitalTrajectoryKeys[0],
				])
			);
		}

		if (!activeVisualizationAmongAvailable) {
			// set first available visualization as active
			dispatch(
				visualizationsActions.setActiveKey(nextAvailableVisualizations[0])
			);
		}

		// change selected
		if (prevType && prevType !== key) {
			const activeSelection = Select.selections.getActive(state);
			const selectedFeatureKey =
				activeSelection?.data?.featureKeysFilter?.keys?.[0];

			if (selectedFeatureKey) {
				const mapKey = Select.app.getLocalConfiguration(
					state,
					'data.interferometry.mapKey'
				);
				const layerKey = Select.app.getLocalConfiguration(
					state,
					'data.interferometry.layerKey'
				);
				const idMasks = Select.app.getLocalConfiguration(
					state,
					'data.interferometry.idMasks'
				);
				const mask = idMasks?.[key];

				const maskedFeatureKey = utils.applyMaskToString(
					mask,
					selectedFeatureKey
				);

				dispatch(
					Action.maps.setLayerSelectedFeatureKeys(mapKey, layerKey, [
						maskedFeatureKey,
					])
				);
				dispatch(
					Action.insar.useInterferometryPointStatistics([maskedFeatureKey])
				);
			}
		}
	};
};

export default {
	setActiveKey,
	setActive,
	updateStateFromView,
	updateStore,
};
