import {connect} from '@gisatcz/ptr-state';

import Action from '../../../state/Action';
import Select from '../../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		minimised: Select.components.get(state, 'controlPanel', 'minimised'),
		chartExpanded: Select.components.get(
			state,
			'controlPanel',
			'chartExpanded'
		),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onStatusChange: status =>
			dispatch(Action.components.set('controlPanel', 'minimised', status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
