export default {
	byKey: {
		los: {
			key: 'los',
			data: {
				nameDisplay: 'LOS',
				group: 'l1',
				availableOrbitalTrajectories: ['asc', 'desc'],
				availableVisualizations: ['displacement', 'trendDynamics'],
				attributeSets: [
					'ids',
					'psi_estimations',
					'height',
					'psi_reliability',
					'psi_trend_estimation',
					'metadata',
					'annual_trends',
					'annual_trends_2020',
					'annual_trends_2017',
					'annual_trends_2015',
					'trend_last_period',
				],
				attributesForTooltip: ['vel_avg', 'cl_dyn'],
			},
		},
		vertg: {
			key: 'vertg',
			data: {
				nameDisplay: 'Vertical (geometric)',
				group: 'l1',
				availableOrbitalTrajectories: ['asc', 'desc'],
				availableVisualizations: [
					'displacement',
					'trendDynamics',
					'acceleration',
					// 'accelerationLastYear',
				],
				attributeSets: [
					'ids',
					'psi_estimations',
					'height',
					'psi_reliability',
					'psi_trend_estimation',
					'metadata',
					'annual_trends',
					'annual_trends_2020',
					'annual_trends_2017',
					'annual_trends_2015',
					// 'trend_last_period',
				],
				attributesForTooltip: ['vel_avg', 'cl_dyn', 'accel_lmy'],
			},
		},
		vert: {
			key: 'vert',
			data: {
				nameDisplay: 'Vertical',
				group: 'l3',
				availableOrbitalTrajectories: ['compo'],
				availableVisualizations: ['displacement', 'acceleration'],
				chartUnavailable: true,
				attributeSets: [
					'ids_compo',
					'vert_2017',
					'vert_2020_1',
					'vert_2017_2021',
					'acceleration',
				],
				attributesForTooltip: ['vel_v', 'acc_v_lmy'],
			},
		},
		ew: {
			key: 'ew',
			data: {
				nameDisplay: 'East <> West',
				group: 'l3',
				availableOrbitalTrajectories: ['compo'],
				availableVisualizations: ['displacement', 'acceleration'],
				chartUnavailable: true,
				attributeSets: [
					'ids_compo',
					'vert_2017_ew',
					'vert_2020_1_ew',
					'vert_2017_2021_ew',
					'acceleration_ew',
				],
				attributesForTooltip: ['vel_ew', 'acc_ew_lmy'],
			},
		},
	},
};
