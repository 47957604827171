import styles from '../styles/styles';
import configuration from '../configuration';

export default {
	interferometry: {
		key: 'interferometry',
		type: 'vector',
		options: {
			renderAs: [
				{
					options: {
						renderingTechnique: 'canvas',
						pointAsMarker: false,
						style: styles.byKey.displacementLosAsPoints.data.definition, // todo add style later?
					},
					boxRangeRange: [1500, null],
				},
				{
					boxRangeRange: [null, 1500],
				},
			],
			pointAsMarker: true,
			features: [],
			hoverable: true,
			hovered: {
				style: 'default',
			},
			selectable: true,
			selected: {
				interferometry: {},
			},
			style: styles.byKey.displacementLosAsMarkers.data.definition, // todo add style later?
			fidColumnName: configuration.interferometry.fidColumnName, // todo add this later?
		},
	},
};
