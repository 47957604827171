import {connect} from '@gisatcz/ptr-state';

import Select from '../../../state/Select';

import presentation from './presentation';

const mapStateToProps = state => {
	return {
		feature: Select.insar.getSelectedPointFeature(state),
		chartUnavailable: Select.insar.types.getActiveTypeChartUnavailable(
			state,
			'chartUnavailable'
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(presentation);
