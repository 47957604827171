import React from 'react';

import MainMap from './MainMap';
import VisualizationsPanel from './VisualizationsPanel';
import IntroOverlay from './IntroOverlay';
import LoadingOverlay from './LoadingOverlay';
import ControlButton from './VisualizationsPanel/ControlButton';

class App extends React.PureComponent {
	render() {
		return (
			<>
				<LoadingOverlay />
				<IntroOverlay />
				<div className="insar-App">
					<MainMap />
					<VisualizationsPanel />
					<ControlButton />
				</div>
			</>
		);
	}
}

export default App;
