import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const AttributesPanel = props => {
	return (
		<div className="insar-AttributesPanel">
			{props.data &&
				props.data.map((attributeSet, index) => {
					return (
						<div key={index} className="insar-AttributesPanel-set">
							{attributeSet.data?.nameDisplay ? (
								attributeSet.data.subset ? (
									<h6>{attributeSet.data?.nameDisplay}</h6>
								) : (
									<h5>{attributeSet.data?.nameDisplay}</h5>
								)
							) : null}
							{attributeSet.attributes.map((attribute, index) => {
								const valueLength = attribute.value?.length;
								const valueClasses = classnames({
									long: valueLength > 15,
								});
								const value = attribute.value;

								return (
									<div key={index} className="insar-AttributesPanel-attribute">
										<div title={attribute.description || null}>
											{attribute.nameDisplay || attribute.key}
										</div>
										<span className={valueClasses}>
											{value || value === 0 ? value : '-'}
										</span>
										<span>{attribute.unit ? attribute.unit : null}</span>
									</div>
								);
							})}
						</div>
					);
				})}
		</div>
	);
};

AttributesPanel.propTypes = {
	data: PropTypes.array,
	featureProperties: PropTypes.object,
};

export default AttributesPanel;
