import './style.scss';
import PropTypes from 'prop-types';

const MapTooltip = ({featureKey, feature, attributes}) => {
	const {gid, id_global, ...featureProps} = feature.properties;

	let records;
	if (attributes) {
		records = attributes.map(attribute => {
			if (attribute) {
				const {key, data} = attribute;
				const {nameDisplay, nameInternal, unit} = data;
				const value = featureProps[key];
				const name = nameInternal || nameDisplay || key;

				return (
					<div className="insar-MapTooltip-attribute" key={key}>
						<div className="insar-MapTooltip-attribute-name">
							{name} {unit ? `(${unit})` : ''}
						</div>
						<div className="insar-MapTooltip-attribute-value">
							{value || value === 0 ? value : '-'}
						</div>
					</div>
				);
			} else {
				return null;
			}
		});
	} else {
		return null;
	}

	return (
		<div className="insar-MapTooltip-content">
			<div className="insar-MapTooltip-header">
				No. <em>{gid || featureKey}</em> {id_global ? `(${id_global})` : ''}
			</div>
			<div className="insar-MapTooltip-body">{records}</div>
		</div>
	);
};

MapTooltip.propTypes = {
	featureKey: PropTypes.string,
	feature: PropTypes.object,
	attributes: PropTypes.array,
};

export default MapTooltip;
