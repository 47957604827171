import React from 'react';
import PropTypes from 'prop-types';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import {
	ControlGroup,
	ControlGroupContent,
	ControlGroupLabel,
} from '../components';

import './style.scss';

class TypeControl extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const {activeTypeKey, types, setActiveType} = this.props;

		return (
			<ControlGroup>
				<ControlGroupLabel className="primary">
					Projected direction (L2)
				</ControlGroupLabel>
				<ControlGroupLabel className="primary" style={{marginLeft: '4.9rem'}}>
					Decomposed direction (L3)
				</ControlGroupLabel>
				<ControlGroupLabel className="alt">Type</ControlGroupLabel>
				<ControlGroupContent>
					<ButtonSwitch
						className="dividedAfterSecondOption"
						onClick={setActiveType}
						ghost
					>
						{types.map(type => (
							<ButtonSwitchOption
								key={type.key}
								value={type.key}
								active={type.key === activeTypeKey}
							>
								{type.data.nameDisplay}
							</ButtonSwitchOption>
						))}
					</ButtonSwitch>
				</ControlGroupContent>
			</ControlGroup>
		);
	}
}

TypeControl.propTypes = {
	types: PropTypes.array,
	setActiveType: PropTypes.func,
	activeTypeKey: PropTypes.string,
};

export default TypeControl;
