import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';

import './style.scss';

class Chart extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			settingsOpen: this.props.settingsOpen,
		};

		this.handleSettings = this.handleSettings.bind(this);
		this.handleExpansion = this.handleExpansion.bind(this);
	}

	handleSettings() {
		this.setState({
			settingsOpen: !this.state.settingsOpen,
		});
	}

	handleExpansion() {
		this.props.setChartExpanded(!this.props.expanded);
	}

	render() {
		const props = this.props;

		return (
			<div className="insar-InterferometryChart">
				<div className="insar-InterferometryChart-header">
					{this.renderTitle()}
					{this.renderControls()}
				</div>
				{props.settingItems?.length ? this.renderSettings() : null}
				{props.children}
			</div>
		);
	}

	renderTitle() {
		return (
			<div className="insar-InterferometryChart-title">
				{this.props.title || 'Chart'}
			</div>
		);
	}

	renderControls() {
		return (
			<div className="insar-InterferometryChart-controls">
				<Button
					invisible
					small
					circular
					primary={this.props.expanded}
					icon="expand"
					onClick={this.handleExpansion}
				/>
				{this.props.settingItems ? (
					<Button
						invisible
						small
						circular
						primary={this.state.settingsOpen}
						icon="settings"
						onClick={this.handleSettings}
					/>
				) : null}
			</div>
		);
	}

	renderSettings() {
		const style = {
			height: this.state.settingsOpen
				? `${this.props.settingItems.length * 4}rem`
				: 0,
		};

		return (
			<div className="insar-InterferometryChart-settings" style={style}>
				{this.props.settingItems.map((item, index) => (
					<div key={index} className="insar-InterferometryChart-settings-item">
						<span>{item.name}</span>
						<div>{item.content}</div>
					</div>
				))}
			</div>
		);
	}
}

Chart.propTypes = {
	children: PropTypes.node,
	title: PropTypes.element,
	settingItems: PropTypes.array,
	settingsOpen: PropTypes.bool,
	expanded: PropTypes.bool,
	setChartExpanded: PropTypes.func,
};

export default Chart;
