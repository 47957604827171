export default {
	occurrence: {
		key: 'occurrence',
		name: 'Water Occurrence (1984-2019)',
		opacity: 0.7,
		options: {
			url: 'https://storage.googleapis.com/global-surface-water/tiles2019/occurrence/{z}/{x}/{y}.png',
			maxNativeZoom: 13,
		},
		type: 'wmts',
	},
	change: {
		key: 'change',
		name: 'Water Occurence Change Intensity (1984-1999 to 2000-2019)',
		opacity: 0.7,
		options: {
			url: 'https://storage.googleapis.com/global-surface-water/tiles2019/change/{z}/{x}/{y}.png',
			maxNativeZoom: 13,
		},
		type: 'wmts',
	},
	seasonality: {
		key: 'seasonality',
		name: 'Water Seasonality (2019)',
		opacity: 0.7,
		options: {
			url: 'https://storage.googleapis.com/global-surface-water/tiles2019/seasonality/{z}/{x}/{y}.png',
			maxNativeZoom: 13,
		},
		type: 'wmts',
	},
	recurrence: {
		key: 'recurrence',
		name: 'Annual Water Recurrence (1984-2019)',
		opacity: 0.7,
		options: {
			url: 'https://storage.googleapis.com/global-surface-water/tiles2019/recurrence/{z}/{x}/{y}.png',
			maxNativeZoom: 13,
		},
		type: 'wmts',
	},
	transitions: {
		key: 'transitions',
		name: 'Water Transitions (First Year to Last Year)',
		opacity: 0.7,
		options: {
			url: 'https://storage.googleapis.com/global-surface-water/tiles2019/transitions/{z}/{x}/{y}.png',
			maxNativeZoom: 13,
		},
		type: 'wmts',
	},
};
