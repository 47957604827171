import {commonActions} from '@gisatcz/ptr-state';
import {isEqual as _isEqual, find as _find} from 'lodash';
import ActionTypes from '../../../constants/ActionTypes';
import Select from '../../Select';

const setActiveKey = commonActions.setActiveKey(
	ActionTypes.INSAR.VISUALIZATIONS
);

const updateStateFromView = commonActions.updateSubstateFromView(
	ActionTypes.INSAR.VISUALIZATIONS
);
const updateStore = commonActions.updateStore(
	Select.insar.visualizations.getSubstate,
	ActionTypes.INSAR.VISUALIZATIONS
);

const handleActiveVisualizationFilteredOutValue = value => {
	return (dispatch, getState) => {
		const activeVisualization = Select.insar.visualizations.getActive(
			getState()
		);
		const currentFilterOutValues = activeVisualization?.data?.filteredOutValues;
		let newFilterOutValues;

		if (!currentFilterOutValues) {
			newFilterOutValues = [value];
		} else {
			const isPresent = currentFilterOutValues.indexOf(value) > -1;
			if (isPresent) {
				newFilterOutValues = currentFilterOutValues.filter(
					currentValue => currentValue !== value
				);
			} else {
				newFilterOutValues = [...currentFilterOutValues, value];
			}
		}

		dispatch(
			actionSetProperty(
				activeVisualization.key,
				'filteredOutValues',
				newFilterOutValues
			)
		);
	};
};

const handleActiveVisualizationFilteredOutInterval = interval => {
	return (dispatch, getState) => {
		const activeVisualization = Select.insar.visualizations.getActive(
			getState()
		);
		const currentFilterOutIntervals =
			activeVisualization?.data?.filteredOutIntervals;
		let newFilterOutIntervals;

		if (!currentFilterOutIntervals) {
			newFilterOutIntervals = [interval];
		} else {
			const isPresent = !!_find(currentFilterOutIntervals, filterOutInterval =>
				_isEqual(filterOutInterval, interval)
			);
			if (isPresent) {
				newFilterOutIntervals = currentFilterOutIntervals.filter(
					filterOutInterval => !_isEqual(filterOutInterval, interval)
				);
			} else {
				newFilterOutIntervals = [...currentFilterOutIntervals, interval];
			}
		}

		dispatch(
			actionSetProperty(
				activeVisualization.key,
				'filteredOutIntervals',
				newFilterOutIntervals
			)
		);
	};
};

const handleActiveVisualizationFilteredOutNoData = isFilteredOut => {
	return (dispatch, getState) => {
		const activeVisualization = Select.insar.visualizations.getActive(
			getState()
		);
		dispatch(
			actionSetProperty(
				activeVisualization.key,
				'filteredOutNoData',
				isFilteredOut
			)
		);
	};
};

const actionSetProperty = (modelKey, property, value) => {
	return {
		type: ActionTypes.INSAR.VISUALIZATIONS.SET_PROPERTY,
		modelKey,
		property,
		value,
	};
};

export default {
	handleActiveVisualizationFilteredOutValue,
	handleActiveVisualizationFilteredOutInterval,
	handleActiveVisualizationFilteredOutNoData,
	setActiveKey,
	updateStateFromView,
	updateStore,
};
