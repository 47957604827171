import MethodControl from './TypeControl';
import OrbitalTrajectoriesControl from './OrbitalTrajectoryControl';
import VisualizationControl from './VisualizationControl';

import './style.scss';

const InterferometryControls = () => {
	return (
		<div className="insar-ControlPanelBlock">
			<MethodControl />
			<OrbitalTrajectoriesControl />
			<VisualizationControl />
		</div>
	);
};

export default InterferometryControls;
